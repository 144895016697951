@font-face {
 font-family: "DMSans";
 src: local("DMSans"),
  url("./fonts/DMSans-Regular.ttf") format("truetype");
}
@font-face {
 font-family: "DMSans";
 font-weight: 700;
 src: local("DMSans"),
  url("./fonts/DMSans-Bold.ttf") format("truetype");
}
@font-face {
 font-family: "Poppins";
 src: local("Poppins"),
  url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
 font-family: "Poppins";
 font-weight: 600;
 src: local("Poppins"),
  url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
 font-family: "Poppins";
 font-weight: 700;
 src: local("Poppins"),
  url("./fonts/Poppins-Bold.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
}
body {
  background-color: #141416;
  font-family: "DMSans";
}
main {
  min-height: 600px;
}
button:not(:disabled), [type=button]:not(:disabled) {
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: inline-block;
  border: 2px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn {
  border: 1px solid transparent;
}
.btn:disabled {
  opacity: 0.65;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
ol, ul, dl {
  margin-bottom: 1rem;
}
label {
  display: inline-block;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000000;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  outline: 0;
}
.modal-dialog {
  margin: 0.5rem;
  position: relative;
  width: auto;
  pointer-events: none;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 24px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
  border-color: #68718A;
  outline: 0;
}
.form-check-input {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #000000;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  print-color-adjust: exact;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.3rem;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.hr-select {
  width: 289px;
}
.hr-select-state {
  width: 240px;
  height: 48px;
}
.MuiList-root {
  max-height: 300px;
}
.invalid-card-data fieldset {
  border: none !important;
}
.invalid-card-data .MuiSelect-select {
  border-color: #DA4D11 !important;
}

.container {
  max-width: 1280px;
  width: 100%;
  padding-right: calc(1.5rem * 0.5);
  padding-left: calc(1.5rem * 0.5);
  margin-right: auto;
  margin-left: auto;
}
.header-container {
  display: flex;
  justify-content: space-between;
}
.header-container {
  .logo-wrap {
    padding: 15px 0 20px 60px;
  }  
}
.header-container .logo-wrap a {
  display: inline-block;
  border-right: 1px solid #353945;
  padding-right: 20px;
  padding-top: 5px;
}
.header-container .logo-wrap img {
  width: 119px;
  height: 48px;  
}
.top-menu-wrap {
  display: flex;
  justify-content: center;
  padding-right: 80px;
}
.sign-in-wrap, .username-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 50px;
}
.username-wrap {
  color: #FCFCFD;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
}
.none-user-avatar-wrap, .user-avatar-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.user-avatar-wrap {
  cursor: pointer;
  position: relative;
  figure {
    img {
      width: 40px;
      height: 40px;
      clip-path: circle();
    }
  }
}
.sign-in-wrap a {
  color: #FCFCFD;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-decoration: none;
}
.container {
  &.banner-container {
    padding-left: 0;
    padding-right: 0;
    &.banner-container-not-logged {
      margin-bottom: 20px;
    }
  }
}
.banner-container img {
  width: 100%;
  height: auto;
}
.top-unity {
  background-image: url('./img/top-unity.svg');
  background-repeat: no-repeat;
  background-position: 65%;
  height: 400px;
  border: 1px solid transparent;
  border-radius: 18px;
  padding: 20px 81px;
  transition: background 1s linear;
   -moz-transition: background 1s linear;
   -webkit-transition: background 1s linear;
  &.top-unity-linked {
    background-image: url('./img/banner.svg');
  }
}
.top-unity-text {
  padding-top: 20px;
  color: #141416;
  font-size: 30px;
  line-height: 32px;
  font-weight: 700;
}
.top-unity-btn-wrap {
  padding-top: 30px;
}
.top-unity-btn-wrap .btn {
  background-color: #FAE87E;
  color: #090909;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px 102px;
  font-family: "DMSans";
  border-radius: 90px;
}
.top-unity-btn-wrap .btn:hover, .top-unity-btn-wrap btn:focus, .top-unity-btn-wrap btn:active {
  background-color: #FAE87E;
  color: #090909;
  border-color: #FAE87E;
}
.unity-logo-wrap img.unity-logo-mobile {
  display: none;
}
.unity-logo-wrap img {
  width: 316px;
  height: 94px;
}
.balance-menu-wrap {
  border: 1px solid #353945;
  box-shadow: 0px 40px 64px -32px #0F0F0F1A;
  border-radius: 24px;
  top: -35px;
  position: relative;
  background: linear-gradient(to right, rgba(35, 38, 47, 1), rgba(35, 38, 47, 0.7));
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  width:1120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins";
  padding: 19px 0;
}
.balance-menu-wrap .balance-title, .balance-menu-wrap .balance-total,
.balance-menu-wrap .free-play-total {
  color: #FCFCFD;
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
}
.balance-menu-wrap .balance-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.balance-menu {
  display: flex;
  justify-content: space-between;
  padding-left: 43px;
}
.total-wrap {
  display: flex;
  justify-content: space-between;
}
.balance-total, .free-play-total {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}
.free-play-total {
  padding-left: 40px;
}
.balance-total figure, .free-play-total figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
}
.balance-total figure {
  padding-right: 2px;
}
.balance-total span, .free-play-total span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.balance-total .price-label, .free-play-total .price-label {
  padding-right: 5px;
}
.free-play-btn-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.free-play-btn-wrap .btn {
  background-color: #FAE87E;
  color: #090909;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 7px 26px;
  font-family: "DMSans";
  border-radius: 90px;
}
.free-play-btn-wrap .btn:hover, .free-play-btn-wrap btn:focus, .free-play-btn-wrap btn:active {
  background-color: #FAE87E;
  color: #090909;
  border-color: #FAE87E;
}
.free-play-text {
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.free-play-text p {
  color: #B2B5C2;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  padding: 0 75px;
  font-family: "DMSans";
}
.dropdown-menu {
  background-color: #141416;
  border: 1px solid #141416;
  border-radius: 20px;
  padding: 16px 12px;
  box-shadow: 0px 0px 5px 1px #FFFFFF1F;
  position: absolute;
  top: calc(100% + 8px);
  right: -84px;
  cursor: default;
  list-style: none;
  z-index: 99999;
  li {
    &.unity-separator {
      border-bottom: 1px solid #353945;
    }
  }
}
.dropdown-menu .dropdown-item {
  display: flex;
  justify-content: flex-start;
  padding: 11px 0;
  cursor: default;
}
.dropdown-menu .user-menu-text {
  color: #777E90;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  padding: 3px 0 0 20px;
  span {
    text-transform: uppercase;
  }
}
.dropdown-menu .dropdown-item:hover .user-menu-text {
  color: #777E90;
}
.dropdown-menu .user-menu-icon img {
  width: 24px;
  height: 24px;
}
.user-menu-actions {
  border-top: 1px solid #353945;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-top: 15px;
}
.user-menu-actions .btn {
  background-color: #3B71FE;
  color: #FCFCFD;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  padding: 7px 34px;
  font-family: "DMSans";
  border-radius: 90px;
}
.user-menu-actions .btn:hover, .user-menu-actions btn:focus, .user-menu-actions btn:active {
  background-color: #3B71FE;
  color: #FCFCFD;
  border-color: #3B71FE;
}
.user-menu-actions .hr-black-btn {
  margin-left: 10px;
  background-color: #141416;
  border: 1px solid #777E90;
  width: 126px;
}
.user-menu-actions .btn.hr-black-btn:hover, .user-menu-actions btn.hr-black-btn:focus, .user-menu-actions btn.hr-black-btn:active {
  color: #FCFCFD;
  background-color: #141416;
  border: 1px solid #777E90;
}

.footer-top {
  padding-top: 100px;
  display: flex;
  justify-content: flex-start;
}
.footer-top .logo-wrap {
  padding: 50px 0;
}
.footer-top-menu {
  margin-left: 50px;
  padding-top: 55px;
  display: flex;
  justify-content: flex-start;
  .ftm-column {
    ul {
      list-style: none;
      li {
        margin-left: 100px;
        margin-bottom: 30px;
        a {
          text-decoration: none;
          color: #777E90;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
        }
      }
    }
  }
  &.ftm-mobile {
    display: none;
  }
}
.footer-bottom {
  padding: 40px 0;
  border-top: 1px solid #353945;
}
.footer-bottom-copyright {
  font-family: "Poppins";
  color: #777E90;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
}
.footer-bottom-copyright .rights-reserved {
  padding-left: 5px;
}

.buy-now .container {
  border: 2px solid #23262F;
  border-radius: 24px;
}
.buy-now-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 65px;
}
.buy-now-wrap-mobile {
  display: none;
  .redeem-amounts-arrows {
    justify-content: flex-start;
    padding-left: 10px;
  }
}
.buy-now-item {
  width: 352px;
}
.buy-now-item-inner {
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: #23262E;
}
.buy-now-img-wrap {
  width: 351px;
  height: 219px;
  background-image: url('./img/buy-now-back.png');
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.buy-now-img-wrap figure {
  padding-top: 45px;
  text-align: center;
  img {
    width: 319px;
    height: 137px;
    display: inline-block;
  }
}
.buy-now-discount {
  color: #FCFCFD;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  font-family: "Poppins";
  background-color: #4CAF50;
  border: 1px solid #4CAF50;
  border-radius: 24px;
  padding: 2px 8px;
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
}
.buy-now-text-wrap h4 {
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 0;
}
.buy-now-text-wrap p {
  color: #B2B5C2;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  padding: 0 20px 20px 20px;
  &.discount-txt-noprice {
    padding-top: 15px;
  }
}
.buy-now-btn-wrap {
  border-top: 1px solid #353945;
  text-align: center;
  padding: 20px 0;
}
.buy-now-btn-wrap .btn {
  background-color: #3B71FE;
  color: #FCFCFD;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px 125px;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
}
.buy-now-btn-wrap .btn:hover, .buy-now-btn-wrap btn:focus, .buy-now-btn-wrap btn:active {
  background-color: #3B71FE;
  color: #FCFCFD;
  border-color: #3B71FE;
}
.buy-now-view-wrap {
  text-align: center;
  padding-bottom: 80px;
}
.buy-now-view-wrap .btn {
  background-color: #141416;
  color: #FCFCFD;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  padding: 12px 16px;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
  border-color: #777E90;
}
.buy-now-view-wrap .btn:hover, .buy-now-view-wrap btn:focus, .buy-now-view-wrap btn:active {
  background-color: #141416;
  color: #FCFCFD;
  border-color: #777E90;
}
.hide {
  display: none;
}

.hik-top h2 {
  color: #FCFCFD;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
}
.hik-top h5 {
  color: #777E90;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  text-align: center;
}
.hik-bottom {
  display: flex;
  justify-content: space-between;
  padding: 60px 50px 0 50px;
  background-image: url('./img/dash-line.svg');
  background-repeat: no-repeat;
  background-position: 155px 130px;
}
.hik-item-text-wrap h3 {
  color: #FCFCFD;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
  font-family: "Poppins";
}
.hik-item-text-wrap p {
  color: #777E90;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  font-family: "Poppins";
}

.promotional-wrap {
  padding: 150px 200px 50px 200px;
  display: flex;
  justify-content: space-between;
}
.promotional-txt-wrap {
  padding-right: 30px;
  padding-top: 100px;
}
.promotional-txt-wrap h2 {
  color: #FCFCFD;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  margin-bottom: 0;
}
.promotional-txt-wrap p {
  color: #777E90;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Poppins";
  padding: 40px 0px;
}
.promotional-btn-wrap .btn {
  background-color: #3B71FE;
  color: #FCFCFD;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px 135px;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
}
.promotional-btn-wrap .btn:hover, .promotional-btn-wrap btn:focus, .promotional-btn-wrap btn:active {
  background-color: #3B71FE;
  color: #FCFCFD;
  border-color: #3B71FE;
}
.pbw-mobile {
  display: none;
}

#sign-in-modal .modal-content, #redeem-modal .modal-content,
.link-unity-modal .modal-content, .alert-dialog .modal-content {
  background-color: #141416;
  border: 1px solid #353945;
  border-radius: 18px;
}
#sign-in-modal .modal-body, .link-account-verification .modal-body {
  padding: 53px 73px 73px 73px;
}
#sign-in-modal .modal-header, #redeem-modal .modal-header,
.link-unity-modal .modal-header {
  border-bottom: none;
  padding: 0;
  display: block;
  position: relative;
}
#sign-in-modal .modal-header .close, #redeem-modal .modal-header .close,
.link-unity-modal .modal-header .close {
  padding: 0;
  margin: 0;
  background-color: #141416;
  border: none;
  height: 37px;
}
#sign-in-modal .modal-header .close figure, #redeem-modal .modal-header .close figure,
.link-unity-modal .modal-header .close figure {
  padding: 0;
  margin: 0;
  height: 48px;
  width: 48px;
  top: 2px;
  right: -6px;
  position: absolute;
}
#sign-in-modal h3, #redeem-modal h3, .link-unity-modal h3 {
  color: #FCFCFD;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;
}
h3 {
  &.verification-code-header {
    color: #FCFCFD;
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
  }
}
p {
  &.verification-code-text {
    font-family: "Poppins";
    color: #777E90;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
    padding: 32px 28px 0 28px;
    padding-top: 20px !important;
  }
}
#sign-in-modal p, #redeem-modal p, .link-unity-modal p {
  font-family: "Poppins";
  color: #777E90;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  padding: 32px 28px 0 28px;
}
#sign-in-modal .tel-input-wrap {
  padding-top: 10px;
}
.sign-in-phone-wrap {
  position: relative;
  .phone-eye-wrap {
    position: absolute;
    top: 27px;
    right: 6px;
    cursor: pointer;
  }
}
#sign-in-modal .form-control {
  border: 2px solid #353945;
  border-radius: 90px;
  font-family: "Poppins";
  color: #777E90;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  background-color: #141416;
  &.phone-input {
    width: 352px;
    padding-right: 30px;
  }
}
.iti--separate-dial-code .iti__selected-dial-code, .form-control::placeholder {
  font-family: "Poppins";
  color: #777E90;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
#sign-in-modal .tel-error-msg {
  font-family: "Poppins";
  color: #C54B3A;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  padding-top: 10px;
}
.sign-in-btn-wrap {
  padding-top: 20px;
}
.sign-in-btn-wrap .btn {
  background-color: #3B71FE;
  color: #FCFCFD;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px 156px;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
}
.sign-in-btn-wrap .btn:hover, .sign-in-btn-wrap btn:focus, .sign-in-btn-wrap btn:active {
  background-color: #3B71FE;
  color: #FCFCFD;
  border-color: #3B71FE;
}
.sign-in-btn-wrap .btn:disabled {
  border-color: #3B71FE;
  cursor: not-allowed;
  opacity: 50%;
}
#sign-in-modal .step2 .full-phone-wrap {
  padding-top: 90px;
  padding-bottom: 20px;
  border-bottom: 1px solid #353945;
}
#sign-in-modal .step2 .full-phone-wrap p {
  color: #FCFCFD;
  padding: 0 15px;
}
#sign-in-modal .step2 .edit-phone-wrap {
  padding-top: 10px;
  text-align: center;
}
#sign-in-modal .step2 .edit-phone-wrap a {
  text-decoration: none;
  font-family: "Poppins";
  color: #777E90;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
#sign-in-modal .step2 .sign-in-btn-wrap {
  padding-top: 45px;
}
#sign-in-modal .step2 .sign-in-btn-wrap .btn {
  padding: 15px 139px;
}
#sign-in-modal .step3 p {
  padding-top: 20px;
}
.resend-code-text {
  font-family: "Poppins";
  color: #777E90;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 20px;
  text-align: center;
}
.code-input-wrap {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
#sign-in-modal .form-control.verification-code,
.link-unity-modal .form-control.verification-code {
  width: 48px;
  height: 64px;
  border: 1px solid #23262F;
  border-radius: 8px;
  background-color: #23262F;
  font-family: "Poppins";
  color: #777E90;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;
}
#sign-in-modal .form-control.verification-code.error,
.link-unity-modal .form-control.verification-code.error {
  border-color: #DA4D11;
  color: #DA4D11;
}
#sign-in-modal .form-control.verification-code:focus, #sign-in-modal .form-control.verification-code:active,
.link-unity-modal .form-control.verification-code:focus, .link-unity-modal .form-control.verification-code:active {
  border: 1px solid #353945;
  background-color: #141416;
  box-shadow: none;
}
.code-error-msg {
  font-family: "Poppins";
  color: #DA4D11;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  padding-top: 25px;
}
.resend-code-link-wrap {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.resend-code-link-wrap.hide {
  display: none;
}
#sign-in-modal .step3 .resend-code-contact-info {
  p {
    font-family: "Poppins";
    color: #B1B5C3;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
    padding: 20px 0;
  }
  a {
    display: block;
    font-family: "Poppins";
    color: #B1B5C3;
    font-size: 17px;
    line-height: 24px;
    font-weight: 700;
    text-align: center;
  }
}
.resend-code-link-wrap a {
  font-family: "Poppins";
  color: #FCFCFD;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 2px 0 0 10px;
  text-decoration: none;
}
.intl-tel-input {
  margin-top: 20px;
}
.intl-tel-input .flag-container .arrow {
  font-size: 14px !important;
  display: none;
}
.intl-tel-input .flag-container .arrow:after {
  color: #777E90;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  font-family: "Poppins";
  color: #777E90;
  font-size: 14px;
  font-weight: 400;
  padding-left: 0 !important;
}
.intl-tel-input .selected-flag .iti-flag {
  margin-top: 13px;
  margin-left: 10px;
}
.country-list .iti-flag {
  margin-top: 0 !important;
}

#redeem-modal .modal-body {
  padding: 47px 38px 38px 38px;
}
.modal-dialog.unity-error-dialog {
  max-width: 613px;
}
.alert-dialog {
  &.unity-error-dialog {
    .modal-body {
      padding: 47px 38px 38px 38px;
      p {
        color: #B1B5C3;
        font-size: 20px;
        line-height: 24px;
        font-weight: 400;
        text-align: center;
        padding: 32px 35px 0 35px;
        &.not-success-mail {
          a {
            font-family: "Poppins";
            color: #FFFFFF;
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            text-align: center;
          }
        }
      }
      .unity-form-wrap {
        border-bottom: none;
      }
      .link-unity-btn-wrap {
        margin-top: 115px;
        text-align: center;
        .btn {
          width: 375px;
        }
      }
      h3 {
        margin-top: 30px;
      }
    }    
  }
  .modal-body {
    padding: 47px 38px 38px 38px;
    h3 {
      color: #FCFCFD;
      font-size: 40px;
      line-height: 48px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 0;
      padding: 0 10px;
    }
    p {
      font-family: "Poppins";
      color: #777E90;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      text-align: center;
      padding: 32px 35px 0 35px;
    }
    .alert-dialog-actions {
      margin-top: 40px;
      text-align: center;
      .btn {
        display: inline-block;
        background-color: #3B71FE;
        color: #FCFCFD;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        padding: 14px 108px;
        font-family: "DMSans";
        border-radius: 90px;
        &:hover, &:focus, &:active {
          background-color: #3B71FE;
          color: #FCFCFD;
          border-color: #3B71FE;
        }
      }
    }
    .alert-contact-info {
      margin-top: 30px;
      a {
        display: block;
        font-family: "Poppins";
        color: #FFFFFF;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        text-align: center;
      }
    }
  }
}
#redeem-modal p {
  padding: 32px 0 0 0;
}
.redeem-btn-wrap {
  padding-top: 20px;
  text-align: center;
}
.redeem-btn-wrap .btn {
  background-color: #FAE87E;
  color: #000000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px 146px;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
}
.redeem-btn-wrap .btn:hover, .redeem-btn-wrap btn:focus, .redeem-btn-wrap btn:active {
  background-color: #FAE87E;
  color: #000000;
  border-color: #FAE87E;
}
.redeem-btn-wrap .btn:disabled {
  border-color: #FAE87E;
  cursor: not-allowed;
  opacity: 50%;
}
.redeem-amounts-wrap {
  padding: 0 40px;
}
#redeem-modal .step1 p {
  padding-top: 10px;
}
.redeem-freeplay-wrap {
  padding: 20px 40px 0 40px;
  display: flex;
  justify-content: center;
  .redeem-freeplay-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4px;
  }
  .redeem-freeplay-text {
    color: #FCFCFD;
    font-size: 14px;
    line-height: 32px;
    font-weight: 600;
    font-family: "Poppins";
    padding-left: 10px;
  }
}
.redeem-amounts-wrap .swiper {
  display: flex !important;
  flex-direction: column-reverse;  
}
.redeem-amounts-arrows {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}
.redeem-amounts-arrow-right {
  padding-left: 20px;
}
.redeem-amounts-items {
  overflow: hidden;
  padding-top: 20px;
}
.redeem-amounts-item {
  /*width: 109px;*/
  height: 40px;
  border: 2px solid #777E90;
  border-radius: 90px;
  color: #777E90;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
}
.redeem-amounts-item.active {
  color: #FCFCFD;
  border-color: #FAE87E;
}
.redeem-amounts-item.disabled {
  opacity: 50%;
  cursor: default;
}
.redeem-locations-wrap {
  padding: 40px 32px;
  .hr-select {
    width: 100%;
    .MuiSelect-select {
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 23px;
      color: #FCFCFD;
    }
    .MuiSvgIcon-root {
      top: 8px;
    }
  }  
}
.redeem-locations-wrap {
  figure {
    display: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
}
.redeem-locations-menu {
  &.hide {
    display: none;
  }
}
.redeem-locations-menu figure {
  display: inline-block;
}
.redeem-locations-menu span {
  padding-left: 10px;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;  
}
#redeem-modal h3.redeem-success-amount {
  padding-top: 20px;
}
#redeem-modal h5 {
  color: #FCFCFD;
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
  padding-top: 30px;
}
#redeem-modal p.redeem-success-link {
  padding-top: 10px;
  a {
    color: #F4F5F6;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
}

.link-unity-modal .step1 {
  max-width: 630px;
}
.link-unity-modal .step1 .modalContent {
  padding: 10px 80px 34px 80px;
}
.unity-logo-wrap-modal figure {
  text-align: center;
}
.unity-logo-modal {
  display: inline-block;
}
.unity-logo-modal-mobile {
  display: none;
}
.link-unity-modal .step2-create-account h3,
.link-unity-modal .step3-link-account h3 {
  font-size: 24px;
  padding: 30px 35px;
}
.link-unity-modal .step1 h3 {
  font-size: 24px;
  padding: 30px 18px 70px 18px;
  line-height: 32px;
}
.link-unity-modal .step3-link-account.not-success h3 {
  font-size: 30px;
}
.link-unity-modal .not-success p {
  padding-top: 15px;
  font-family: "Poppins";
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
}
.link-unity-modal .not-success-mail {
  margin-bottom: 100px;
}
.link-unity-modal .not-success-mail a {
  font-family: "Poppins";
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
  color: #FFFFFF;
}
.link-unity-modal .step1 p {
  padding-top: 0;
}
.link-unity-btns-wrap {
  display: flex;
  justify-content: space-between;
  padding: 45px 28px 0 28px;
}
.sign-up-unity-btn-wrap .btn, .link-unity-btn-wrap .btn {
  background-color: #3B71FE;
  color: #FCFCFD;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px 0;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
  width: 170px;
}
.sign-up-unity-btn-wrap .btn:hover, .sign-up-unity-btn-wrap btn:focus, .sign-up-unity-btn-wrap btn:active {
  background-color: #3B71FE;
  color: #FCFCFD;
  border-color: #3B71FE;
}
.link-unity-btn-wrap .btn {
  background-color: #FAE87E;
  color: #000000;
}
.link-unity-btn-wrap .btn:hover, .link-unity-btn-wrap btn:focus, .link-unity-btn-wrap btn:active {
  background-color: #FAE87E;
  color: #000000;
  border-color: #FAE87E;
}
.link-unity-modal .step2-create-account {
  max-width: 753px;
}
.link-unity-modal .step3-link-account {
  max-width: 630px;
}
.link-unity-modal .step2-create-account .modalContent {
  padding: 0 55px;
}
.link-unity-modal .step3-link-account .modalContent {
  padding: 0 104px;
}
.unity-form-row {
  .hr-select {
    .MuiSelect-select {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .MuiSvgIcon-root {
      top: 8px;
    }
  }
}
.unity-form-wrap {
  border-bottom: 1px solid #353945;
}
.unity-form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 32px;
  .unity-phone-item {
    width: 289px;
  }
}
.link-unity-modal .form-control {
  border: 2px solid #353945;
  border-radius: 12px;
  font-family: "Poppins";
  color: #777E90;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  background-color: #141416;
  height: 48px;
  &.create-account {
    width: 289px;
  }  
}
.link-unity-modal .form-control.error {
  border-color: #DA4D11;
}
.link-unity-modal .form-label {
  text-transform: uppercase;
  font-family: "Poppins";
  color: #B1B5C3;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  margin-bottom: 12px;
}
.link-unity-modal .span-label {
  font-family: "Poppins";
  color: #B1B5C3;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}
.link-unity-modal p {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 32px 0 0 0;
  text-align: center;
}
.link-unity-modal .form-check label {
  color: #777E90;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding-left: 5px;
  padding-top: 3px;
}
.link-unity-modal .form-check label a {
  color: #ffffff;
}
.link-unity-modal .form-check .form-check-input {
  background-color: #23262F;
  border: 2px solid #353945;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}
.link-unity-modal .form-check-input:checked[type=checkbox] {
  background-color: #3B71FE;
  border-color: #3B71FE;
}
.link-unity-modal .step2-create-account .sign-up-unity-btn-wrap {
  margin: 90px 0 50px 0;
  text-align: center;
}
.link-unity-modal .step2-create-account .sign-up-unity-btn-wrap .btn {
  width: 375px;
}
.link-unity-modal .step3-link-account .link-unity-btn-wrap {
  margin: 10px 0 70px 0;
  text-align: center;
}
.link-unity-modal .step3-link-account .link-unity-btn-wrap .btn {
  width: 375px;
}
.link-unity-modal .email-error-msg, .link-unity-modal .phone-error-msg,
.link-unity-modal .date-birth-error-msg, .link-account-form-wrap .email-error-msg,
.invalid-format-error-msg {
  font-family: "Poppins";
  color: #DA4D11;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding-top: 5px;
}
.MuiMenuItem-root {
  &.link-account-menu {
    display: none;
  }
}
.step3-link-account h3 {
  padding: 45px 35px;
}
.step3-link-account .unity-form-item {
  padding-bottom: 35px;
}
.unity-form-item {
  .intl-tel-input {
    margin-top: 0;
    .form-control {
      padding-left: 12px !important;
      width: 289px;
    }
  }
  .flag-container {
    display: none;
  }
  .email-input {
    width: 289px;
  }
  .phone-error-msg {
    display: none;
  }
  .date-birth-error-msg, .invalid-format-error-msg {
    display: none;
  }
  &.create-unity-error {
    .form-control {
      border-color: #DA4D11;
    }
    .phone-error-msg {
      display: block;
    }
    .date-birth-error-msg, .invalid-format-error-msg {
      display: block;
    }
    .email-error-msg {
      display: block;
    }
  }
}
.step3-link-account .unity-form-wrap {
  border-bottom: none;
}

.go-back {
  padding-top: 30px;
}
.go-back-btn-wrap .btn {
  background-color: #141416;
  color: #FCFCFD;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  padding: 12px 16px;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
  border: 2px solid #777E90;
  width: 117px;
}
.go-back-btn-wrap .btn img {
  left: -7px;
  position: relative;
}
.go-back-btn-wrap .btn:hover, .go-back-btn-wrap btn:focus, .go-back-btn-wrap btn:active {
  background-color: #141416;
  color: #FCFCFD;
  border-color: #777E90;
}
.header-container .logo-wrap.logo-wrap-left {
  padding-left: 0;
}
.confirm-and-pay-content {
  display: flex;
  justify-content: space-between;
  padding-top: 110px;  
  padding-bottom: 50px;
}
.payment-method-wrap {
  width: 40%;
}
.payment-method-wrap h3 {
  color: #FCFCFD;
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  padding-bottom: 10px;
}
.payment-method-wrap .payment-switcher {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #353945;
  border-top: 1px solid #353945;
  margin: 40px 0;
  padding: 40px 0px;
}
.payment-method-wrap .payment-switcher h5 {
  font-family: "Poppins";
  color: #FCFCFD;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.payment-method-wrap .payment-switcher-options a {
  display: inline-block;
  text-decoration: none;
  font-family: "DMSans";
  color: #777E90;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  margin-left: 20px;
}
.payment-method-wrap .payment-switcher-options a.payment-switcher-selected {
  color: #23262F;
  background-color: #E6E8EC;
  border: 1px solid #E6E8EC;
  border-radius: 100px;
  padding: 5px 12px;
  cursor: default;
}
.credit-card-method-item {
  padding-bottom: 25px;
}
.credit-card-method .form-label, .acc-form-item .form-label {
  text-transform: uppercase;
  font-family: "Poppins";
  color: #B1B5C3;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  margin-bottom: 12px;
  display: block;
}
.credit-card-method-item .hr-select {
  width: 351px;
}
.credit-card-method-item {
  .credit-card-number {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #FCFCFD;
    font-family: "Poppins";
  }
  .credit-card-expiration {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #777E90;
    font-family: "Poppins";
  }
}
.credit-card-method .form-control {
  border: 2px solid #353945;
  border-radius: 12px;
  font-family: "Poppins";
  color: #777E90;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  background-color: #141416;
  height: 48px;
  width: 240px;
}
.credit-card-method .form-control.error {
  border-color: #DA4D11;
}
.pay-btn-wrap div {
  width: 50%;
}
.pay-btn-wrap .btn {
  background-color: #3B71FE;
  color: #FCFCFD;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  padding: 15px 25px;
  font-family: "DMSans";
  border-radius: 90px;
  display: inline-block;
  width: 240px;
  margin-top: 30px;
}
.pay-btn-wrap .btn:hover, .pay-btn-wrap btn:focus, .pay-btn-wrap btn:active {
  background-color: #3B71FE;
  color: #FCFCFD;
  border-color: #3B71FE;
}
.add-credit-card {
  padding-top: 50px;
  border-top: 1px solid #353945;
}
.acc-row-expiration {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.acc-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  height: 35px;
  .acc-top-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.acc-top h5 {
  font-family: "Poppins";
  color: #FCFCFD;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.acc-top-icons {
  display: flex;
  figure {
    margin-right: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.card-selected {      
      span {
        width: 53px;
        height: 34px;
        background-size: cover;
        &.cc-visa {
          background-image: url('./img/cc-visa-selected.svg');        
        }
        &.cc-master {
          background-image: url('./img/cc-master-selected.svg');        
        }
        &.cc-amex {
          background-image: url('./img/cc-amex-selected.svg');        
        }
        &.cc-discover {
          background-image: url('./img/cc-discover-selected.svg');        
        }
      }   
    }
    span {
      width: 37px;
      height: 24px;
      background-repeat: no-repeat;
      &.cc-visa {
        background-image: url('./img/cc-visa.svg');        
      }
      &.cc-master {
        background-image: url('./img/cc-master.svg');        
      }
      &.cc-amex {
        background-image: url('./img/cc-amex.svg');        
      }
      &.cc-discover {
        background-image: url('./img/cc-discover.svg');        
      }
    }
  }  
}
.acc-form-charged {
  margin-top: 30px;
  font-family: "Poppins";
  color: #B1B5C3;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  span {
    
  }
}
.acc-form-item {
  padding-bottom: 20px;
  width: 100%;
  .invalid-format-error-msg {
    display: none;
  }
  &.paysafe-form-error {
    .invalid-format-error-msg {
      display: block;
    }
  }
}
.acc-form-item .form-control {
  border: 2px solid #353945;
  border-radius: 12px;
  font-family: "Poppins";
  color: #777E90;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  background-color: #141416;
  height: 48px;
}
.add-credit-card .form-check .form-check-input {
  background-color: #23262F;
  border: 2px solid #353945;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}
.add-credit-card .form-check-input:checked[type=checkbox] {
  background-color: #3B71FE;
  border-color: #3B71FE;
}
.add-credit-card .form-check {
  padding-bottom: 25px;
}
.add-credit-card .form-check label {
  color: #777E90;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  padding-left: 10px;
  padding-top: 3px;
}

.acc-form-item {
  &.valid-card {
    .form-control {
      border-color: #58C27D;
    }
    &::after {
      content: "";
      background-image: url('./img/card-valid-icon.svg');
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 40px;
      right: 6px;
    }
  }
  &.invalid-card-data {
    .form-control {
      border-color: #DA4D11;
    }
  }
}
.acc-row-number {
  position: relative;
}
.wallet-wrap {
  width: 43%;
}
.wallet-inner {
  border: 1px solid #353945;
  border-radius: 24px;
  box-shadow: 0px 40px 32px -24px #0F0F0F1F;
  padding: 32px 32px 120px 32px;
  background-color: #141416;  
}
.wallet-wrap h4 {
  font-family: "Poppins";
  color: #FCFCFD;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
.wallet-wrap .buy-now-text-wrap h4 {
  color: #ffffff;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 0;
  font-family: "DMSans";
}
.wallet-item-wrap {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 80px;
}
.wallet-total {
  display: flex;
  justify-content: space-between;
  color: #FCFCFD;
  font-family: "Poppins";
  background-color: #353945;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 8px 12px;
}
.wallet-total-sum {
  font-family: "Poppins";
  color: #FCFCFD;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.wtt-text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}
.wtt-currency {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  color: #777E90;
}
.paypal-method-top {
  display: flex;
  justify-content: space-between;
}
.paypal-method-text {
  font-family: "Poppins";
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #FCFCFD;
}
.pm-btn-wrap {
  padding: 24px 0;
  text-align: center;
}
.pm-btn-wrap .btn {
  background-color: #F6C657;
  padding: 15px 25px;
  border-radius: 90px;
  display: inline-block;
  width: 496px;
}
.pm-btn-wrap .btn:hover, .pm-btn-wrap btn:focus, .pm-btn-wrap btn:active {
  background-color: #F6C657;
  border-color: #F6C657;
}
.pm-logo-wrap {
  text-align: center;
}
.pm-logo-wrap figure {
  display: inline-block;
}
.payment-success-wrap {
  width: 40%;
  font-family: "Poppins";
  .dollars-added {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #FCFCFD;
    padding: 60px 0 20px 0;
  }
  h3 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    color: #FCFCFD;
  }
  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #FCFCFD;
    padding-top: 20px;
  }
  .purchase-completed {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #777E90;
    padding-top: 20px;
  }
  .order-details {
    padding-top: 40px;
    .order-details-row {
      display: flex;
      justify-content: flex-start;
      gap: 130px;
      padding: 10px 0;
      .od-label {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: #777E90;
        width: 130px;
      }
      .od-value {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: #FCFCFD;
      }
    }
  }
  .order-notes {
    padding-top: 50px;
    .order-notes-label {
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      color: #777E90;
    }
    .order-notes-text {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #FCFCFD;
    }
  }
  .total-wrap {
    justify-content: flex-start;
    .balance-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #FCFCFD;
      font-size: 14px;
      line-height: 32px;
      font-weight: 600;
    }
    .balance-total {
      color: #FCFCFD;
      font-size: 14px;
      line-height: 32px;
      font-weight: 600;
    }
    .free-play-total {
      color: #FCFCFD;
      font-size: 14px;
      line-height: 32px;
      font-weight: 600;
    }
  }
}
.wallet-inner {
  &.in-success-block {
    padding-bottom: 6px;
  }
}
.in-success-block {
  .buy-now-discount {
    display: none;
  }
  .buy-now-text-wrap {
    h4 {
      display: none;
    }
    p {
      padding-top: 50px;
      padding-bottom: 96px;
    }
  }
}
.congrat-mobile {
  display: none;
  h3 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    color: #FCFCFD;
  }
  .purchase-completed {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #777E90;
    padding-top: 20px;
  }
}

.account-wrap {
  display: flex;
  justify-content: space-between;
}
.account-menu {
  padding-top: 30px;
  width: 30%;
  &.support-menu {
    h3 {
      font-size: 48px;
      line-height: 56px;
      font-weight: 700;
      color: #FCFCFD;
      padding: 60px 0 30px 0;
    }
    .account-menu-inner {
      background-color: transparent;
      border: none;
      box-shadow: none;
      padding-left: 0;
    }
  }  
  .account-menu-inner {
    background-color: #23262F;
    border: 1px solid #353945;
    border-radius: 16px;
    padding: 48px 32px;
    box-shadow: 0px 64px 64px -48px #0F0F0F14;
    width: 256px;
    
    .account-menu-item {
      display: flex;
      justify-content: space-between;
      padding: 7px 0;
      a {
        width: 156px;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        color: #777E90;
        width: 155px;
        text-decoration: none;
        &.account-active {
          color: #FCFCFD;
        }
      }   
    }
  }
  .account-menu-select {
    display: none;
  }
}
.account-content {
  width: 67%;
  padding-top: 25px;
  &.ph {
    width: 80%;
  }
  .account-content-inner {
    padding-right: 100px;
    h3 {
      font-size: 48px;
      line-height: 56px;
      font-weight: 700;
      color: #FCFCFD;
    }
    h5 {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #FCFCFD;
      margin-top: 70px;
      margin-bottom: 30px;
    }
    .acc-top h5 {
      margin: 0;
    }
    .acc-row-expiration {
      .acc-form-item {
        width: 48%;
      }
    }
    .add-payment-btn-wrap {
      padding-top: 60px;
      .btn {
        background-color: #3B71FE;
        color: #FCFCFD;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        padding: 10px 15px;
        font-family: "DMSans";
        border-radius: 90px;
        width: 183px;
        &:hover {
          background-color: #3B71FE;
          color: #FCFCFD;
          border-color: #3B71FE;
        }
        &:focus {
          background-color: #3B71FE;
          color: #FCFCFD;
          border-color: #3B71FE;
        }
        &:active {
          background-color: #3B71FE;
          color: #FCFCFD;
          border-color: #3B71FE;
        }
      }
    }
    .personal-info-form-wrap {
      border-bottom: 1px solid #353945;
      .pi-form-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding-bottom: 32px;
        .pi-form-item {
          position: relative;
          .form-control {
            border: 2px solid #353945;
            border-radius: 12px;
            font-family: "Poppins";
            color: #777E90;
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            background-color: #141416;
            height: 48px;
            width: 358px;
            padding-right: 35px;
                       
            &.error {
              border-color: #DA4D11;
            }
          }
          .form-label {
            text-transform: uppercase;
            font-family: "Poppins";
            color: #B1B5C3;
            font-size: 12px;
            line-height: 12px;
            font-weight: 700;
            margin-bottom: 12px;
          }
          &::after {
            content: "";
            background-image: url('./img/lock.svg');
            background-repeat: no-repeat;
            width: 24px;
            height: 24px;
            position: absolute;
            top: 40px;
            right: 6px;
          }
        }
      }
    }
    .link-account-form-wrap {
      padding-top: 50px;
      .la-form-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding-bottom: 32px;
        .la-form-item {
          .form-control {
            border: 2px solid #353945;
            border-radius: 12px;
            font-family: "Poppins";
            color: #777E90;
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            background-color: #141416;
            height: 48px;
            width: 387px;
                       
            &.error {
              border-color: #DA4D11;
            }            
          }
          .form-label {
            text-transform: uppercase;
            font-family: "Poppins";
            color: #B1B5C3;
            font-size: 12px;
            line-height: 12px;
            font-weight: 700;
            margin-bottom: 12px;
          }
          .link-unity-btn-wrap {
            padding-top: 30px;
            .btn {
              padding: 10px 0;
              width: 192px;
            }
          }
          &.link-unity-error {
            .form-control {
              border-color: #DA4D11;
            }
          }
        }
      }
    }
    .credit-card-wrap {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      color: #E6E8EC;
      margin-top: 70px;
      .credit-card {
        display: flex;
        justify-content: space-between;
        font-family: "Poppins";
        padding-top: 30px;
        .credit-card-number {
          font-size: 14px;
          line-height: 24px;
          font-weight: 500;
          color: #FCFCFD;
        }
        .credit-card-expiration {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          color: #777E90;
        }
        .credit-card-btn-wrap {
          .btn {
            background-color: #3B71FE;
            color: #FCFCFD;
            font-size: 14px;
            line-height: 16px;
            font-weight: 700;
            padding: 10px 10px;
            font-family: "DMSans";
            border-radius: 90px;
            width: 123px;
            &:hover {
              background-color: #3B71FE;
              color: #FCFCFD;
              border-color: #3B71FE;
            }
            &:focus {
              background-color: #3B71FE;
              color: #FCFCFD;
              border-color: #3B71FE;
            }
            &:active {
              background-color: #3B71FE;
              color: #FCFCFD;
              border-color: #3B71FE;
            }
          }
        }
      }
    }
  }
}
.support-wrap {
  .MuiAccordion-root {
    border-top: 1px solid #353945;
    padding: 25px 0;
    .MuiAccordionSummary-root {
      padding: 0;
    }
  }
  .MuiAccordionDetails-root {
    padding: 8px 0 16px 0;
    .MuiTypography-root {
      color: #777E90;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
  .MuiTypography-root {
    font-family: "Poppins";
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  ol {
    padding-left: 30px;
    padding-top: 20px;
    li {
      font-family: "Poppins";
      color: #777E90;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}
.support-messages-wrap {
  padding-top: 230px;
  width: 60%;
  .messages-content {
    font-family: "Poppins";
    max-height: 600px;
    overflow-y: auto;
    padding-right: 30px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #23262F;
      outline: 1px solid #23262F;
    }
    .messages-content-inner {
      border-top: 1px solid #353945;
      padding: 40px 0 0 40px;      
    }
    .messages-date {
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      color: #FCFCFD;
      text-align: center;
    }
    .messages-list {
      padding-top: 40px;
      .messages-item {
        margin-top: 20px;
        .messages-item-content {
          display: flex;
          justify-content: flex-start;
          gap: 15px;
          .message-text-wrap {
            background-color: #23262F;
            border: 1px solid transparent;
            border-radius: 32px;
            padding: 16px 24px;
            .message-text {
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              color: #FCFCFD;
            }
          }
        }    
        .messages-item-time {
          text-align: right;
          font-size: 12px;
          line-height: 20px;
          font-weight: 600;
          color: #B1B5C3;
        }
      }
      .messages-answer {
        display: flex;
        justify-content: flex-end;
        padding-top: 25px;
        .answer-text-wrap {
          background-color: #3B71FE;
          border: 1px solid transparent;
          border-radius: 32px;
          padding: 16px 24px;
          .answer-text {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: #FCFCFD;
          }
        }
      }
    }
  }
  .messages-send-wrap {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .messages-send {
      position: relative;
      .form-control {
        border: 2px solid #353945;
        border-radius: 90px;
        font-family: "Poppins";
        color: #777E90;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        background-color: #141416;
        height: 48px;
        width: 451px;
        padding-right: 45px;
      }
      .btn {
        background-color: #3B71FE;
        color: #FCFCFD;
        padding: 4px;
        border-radius: 100px;
        width: 32px;
        height: 32px;
        position: absolute;
        top: 8px;
        right: 8px;
      }
    }    
  }
}

.tmp-page-content {
  color: #ffffff;
}
#buy-now-view-all-content .buy-now-item {
  margin-bottom: 40px;
}
.paysafe-error {
  font-family: "Poppins";
  color: #DA4D11;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.selectedCardHidden {
  display: none;
}

.modal-dialog {
  &.privacy-policy {
    max-width: 700px;
    .privacy-policy-content {
      padding: 20px;
      max-height: 600px;
      overflow-y: auto;
      h1, h2 {
        color: #FCFCFD;
        font-size: 40px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
      }
      ol {
        li {
          font-size: 14px;
          font-weight: 400;
          font-family: "Poppins";
          color: #777E90;
        }
      }
      table {
        td {
          font-size: 14px;
          font-weight: 400;
          font-family: "Poppins";
          color: #777E90;
        }
      }
    }
  }
}

.purchase-history-wrap {
  margin-top: 50px;
  .ph-desktop {
    .ph-table-head, .ph-table-row {
      display: flex;
      justify-content: space-between;
      padding: 15px 0;
      border-bottom: 1px solid #353945;
      .ph-cell {
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        font-family: "Poppins";
        color: #B1B5C3;
        text-transform: uppercase;
        &.ph-cell-date {
          width: 12%;
        }
        &.ph-cell-transaction-id {
          width: 15%;
        }
        &.ph-cell-transaction-type {
          width: 18%;
        }
        &.ph-cell-description {
          width: 15%;
        }
        &.ph-cell-freeplay {
          width: 10%;
          text-align: center;
        }
        &.ph-cell-w {
          width: 5%;
          text-align: center;
        }
        &.ph-cell-amount {
          width: 10%;
          text-align: center;
        }
        &.ph-cell-status {
          width: 10%;
          span {
            width: 58px;
          }
        }
      }
    }
    .ph-table-row {
      .ph-cell {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: #FFFFFF;
        text-transform: unset;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.approved {
          color: #49CC1D;
        }
        &.pending {
          color: #F6B635;
        }
        &.declined {
          color: #CC1D1D;
        }
      }
    }    
  }  
  .ph-mobile {
    display: none;
    border-bottom: 1px solid #353945;
    .phm-head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .phm-head-date {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        font-family: "Poppins";
        color: #FFFFFF;
      }
      .phm-head-status {
        display: flex;
        flex-direction: column;
        justify-content: center;
        span {
          display: block;
          width: 12px;
          height: 12px;
          clip-path: circle();
          margin-right: 5px;
          &.approved {
            background-color: #49CC1D;
          }
          &.pending {
            background-color: #F6B635;
          }
          &.declined {
            background-color: #CC1D1D;
          }
        }
      }
    }
    .phm-content {
      .phm-content-row {
        display: flex;
        justify-content: flex-start;
        padding: 2px 0;
        .phm-content-title {
          font-size: 12px;
          line-height: 25px;
          font-weight: 700;
          font-family: "Poppins";
          color: #B1B5C3;
          text-transform: uppercase;
          width: 40%;
        }
        .phm-content-value {
          font-size: 14px;
          line-height: 24px;
          font-weight: 500;
          font-family: "Poppins";
          color: #FFFFFF;
          width: 60%;
          &.approved {
            color: #49CC1D;
          }
          &.pending {
            color: #F6B635;
          }
          &.declined {
            color: #CC1D1D;
          }
          span {
            display: block;
            &.phm-awarded-text {
              font-size: 10px;
              line-height: 16px;
              font-weight: 700;
              font-family: "DMSans";
              color: #B2B5C2;
            }
          }
        }
      }      
    }
  }
}
.MuiTooltip-tooltipPlacementTop {
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
  font-family: "DMSans";
  color: #B2B5C2;
}
.load-more-btn-wrap {
  text-align: center;
  margin-top: 40px;
  .btn {
    background-color: #141416;
    color: #FCFCFD;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    padding: 12px 16px;
    font-family: "DMSans";
    border-radius: 90px;
    display: inline-block;
    border: 2px solid #777E90;
    width: 117px;
  }  
}
.intl-tel-input {
  .country-list {
    .country {
      &.highlight {
        display: none;
      }
    }
    .divider {
      display: none;
    }
  }
}

@media screen and (max-width: 1273px) {
  .account-content {
    width: 50%;
    .account-content-inner {
      padding-right: 0;
      .personal-info-form-wrap {
        .pi-form-row {
          flex-direction: column;
          .pi-form-item {
            width: 358px;
          }
        }
      }
      .link-account-form-wrap {
        .la-form-row {
          flex-direction: column;
        }
      }
    }
  }
  .hr-select-state {
    width: 100%;
  }
  .acc-row-expiration .acc-form-item {
    width: 50%;
  }
}

.hr-modal-dialog {
  .MuiLinearProgress-indeterminate {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1250px) {
  .buy-now-wrap {
    padding: 20px;
  }
  .buy-now-item {
    width: 282px;
  }
  .buy-now-btn-wrap .btn {
    padding: 15px 95px;
  }
  .buy-now-img-wrap {
    width: 282px;
  }
  .buy-now-img-wrap figure img {
    width: 282px;
  }
  .buy-now .container {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
}  

@media screen and (max-width: 1170px) {
  .balance-menu-wrap {
    width: 97%;
  }
  .free-play-text p {
    padding: 0 10%;
  }
  .balance-menu {
    padding-left: 2%;
  }
  .pbw-desktop {
    display: none;
  }
  .pbw-mobile {
    display: block;
    padding-top: 50px;
  }
  .pm-btn-wrap .btn {
    width: 100%;
  }
  .promotional-wrap {
    padding: 150px 50px 50px 50px;
  }
  .hik-bottom {
    padding: 60px 10px 0 10px;
  }
  .top-unity-text {
    padding-right: 32%;
  }
}

@media screen and (max-width: 1140px) {
  .free-play-text {
    width: 32%;
  }
  .free-play-text p {
    padding: 0 3%;
  }
}

@media screen and (max-width: 1030px) {
  .account-menu {
    &.account-menu-ph {
      width: 20%;
      .account-menu-inner {
        width: 150px;
        padding: 10px 5px;
        .account-menu-item {
          a {
            width: 117px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .buy-now-wrap {
    display: none;
  }
  .buy-now-view-wrap {
    display: none;
  }
  .buy-now-wrap-mobile {
    display: block;
  }
  .buy-now .container {
    border: none;
  }
  .buy-now-item {
    margin-top: 20px;
    text-align: center;
    display: inline-block;
  }
  .swiper-backface-hidden {
    .swiper-slide {
      text-align: center;
    }
  }
  .buy-now-item-inner {
    display: inline-block;
  }
  .buy-now-img-wrap {
    text-align: left;    
  }
  .buy-now-view-wrap {
    padding-bottom: 0;
  }
  .hik-bottom {
    flex-direction: column;
    padding: 30px 50px 0 50px;
    background-image: none;
  }
  .hik-item-text-wrap p {
    padding-bottom: 20px;
  }
  .hik-item-img-wrap {
    text-align: center;
  }
  .hik-item-img-wrap img {
    display: inline-block;
  }
  .promotional-img-wrap figure {
    text-align: center;
  }
  .promotional-img-wrap img {
    display: inline-block;
  }
  .promotional-wrap {
    flex-direction: column;
    padding: 0;
  }
  .promotional-txt-wrap {
    padding: 0 20px;
  }
  .promotional-txt-wrap h2 {
    text-align: center;
  }
  .promotional-txt-wrap p {
    text-align: center;
  }
  .promotional-btn-wrap {
    text-align: center;
  }
  .payment-success-wrap .total-wrap {
    flex-direction: column;
  }
  .payment-success-wrap .total-wrap .balance-total,
  .payment-success-wrap .total-wrap .free-play-total {
    justify-content: flex-start;
    padding-left: 0;
  } 
}

@media screen and (max-width: 930px) {
  .free-play-total {
    padding-left: 10px;
  }
  .balance-menu-wrap {
    flex-direction: column;
    width: 96%;
    top: -63px;
    padding-top: 0;
    padding-bottom: 3px;
  }
  .balance-menu-wrap .balance-title {
    flex-direction: row;
  }
  .balance-menu {
    flex-direction: column;
    padding-left: 0;
    border-bottom: 1px solid #353945;
    padding-bottom: 3px;
  }
  .free-play-btn-wrap {
    flex-direction: row;
    padding-bottom: 15px;
    padding-top: 15px;
  }
  .balance-total {
    flex-direction: row;
    figure {
      img {
        width: 20px;
        height: 11px;
      }
    }
  }
  .free-play-total {
    flex-direction: row;
    figure {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .free-play-total {
    padding-left: 0;
    padding-right: 20px;
  }
  .balance-total span, .free-play-total span {
    flex-direction: row;
  }
  .free-play-text {
    width: 100%;
  }
  .free-play-text p {
    padding: 0 22px;
    text-align: center;
  }  
}

@media screen and (max-width: 767px) {
  .header-container .logo-wrap {
    padding-left: 10px;
  }
  .top-menu-wrap {
    padding-right: 0;
  }
  .sign-in-wrap {
    padding-right: 20px;
  }
  .footer-container {
    padding: 0 20px;
  }
  .footer-top {
    flex-direction: column;
  }
  .footer-top-menu {
    margin-left: 0;
    padding-top: 0;
  }
  .footer-top-menu ul {
    padding-left: 0;
  }
  .footer-top-menu ul li {
    margin-left: 0;
  }
  .footer-bottom-copyright {
    flex-direction: column;
  }
  .footer-bottom-copyright .rights-reserved {
    padding-left: 0;
  }
  .top-unity {
    padding: 10px 20px 20px 20px;
    height: auto;
    background-size: cover;
    &.top-unity-linked {
      height: 258px;
    }
  }
  .unity-logo-wrap img {
    width: 34%;
    height: auto;
    display: inline-block;
  }
  .container {
    &.banner-container {
      padding-right: 0.75rem;
      padding-left: 0.75rem;
    }
  }
  .top-unity-text {
    font-size: 12px;
    line-height: 18px;
    padding-top: 10px;
    padding-right: 100px;
    span {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .top-unity-btn-wrap {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 60px;
  }
  .top-unity-btn-wrap .btn {
    display: inline-block;
    padding: 6px 53px;
  }
  .free-play-btn-wrap .btn {
    padding: 6px 11px;
  }
  .sign-in-btn-wrap, #sign-in-modal .tel-input-wrap {
    text-align: center;
  }
  .sign-in-btn-wrap {
    padding-bottom: 20px;
  }
  .sign-in-btn-wrap .btn {
    display: inline-block;
    padding: 15px 141px;
    width: 100%;
  }
  #sign-in-modal .step2 .full-phone-wrap {
    padding-top: 10px;
  }
  #sign-in-modal .step2 .sign-in-btn-wrap .btn {
    padding: 15px 122px;
  }
  #sign-in-modal .code-input-wrap {
    padding: 10px 0 0 0;
  }
  #sign-in-modal .intl-tel-input {
    .form-control {
      &.phone-input {
        width: 100%;
      }
    }
  }
  #redeem-modal .modal-body {
    padding: 10px;
  }
  .redeem-btn-wrap .btn {
    padding: 15px 143px;
  }
  .unity-logo-modal-mobile {
    display: inline-block;
  }
  .unity-logo-modal {
    display: none;
  }
  .link-unity-modal .step1 .modalContent, .link-unity-modal .step2-create-account .modalContent,
  .link-unity-modal .step3-link-account .modalContent {
    padding: 0;
  }
  .link-unity-modal .step1 .modal-body, .link-unity-modal .step2-create-account .modal-body,
  .link-unity-modal .step3-link-account .modal-body {
    padding-top: 0;
  }
  .link-unity-modal .step1 h3, .link-unity-modal .step2-create-account h3,
  .link-unity-modal .step3-link-account h3 {
    padding: 30px 0;
  }
  .link-unity-modal .step1 p, .link-unity-modal .step2-create-account p {
    padding: 0;
  }
  .link-unity-btns-wrap, .unity-form-row {
    flex-direction: column;
    padding-bottom: 20px;
  }
  .sign-up-unity-btn-wrap .btn, .link-unity-btn-wrap .btn {
    width: 230px;
    display: inline-block;
  }
  .alert-dialog {
    &.unity-error-dialog {
      .modal-body {
        padding: 20px 35px;
        .link-unity-btn-wrap {
          .btn {
            width: 230px;
          }
        }
        h3 {
          font-size: 24px;
        }
        p {
          font-size: 18px;
          padding: 32px 5px 0 5px;
          &.not-success-mail {
            padding: 32px 5px 0 5px;
            a {
              font-size: 14px;
            }
          }
        }
        .link-unity-btn-wrap {
          margin-top: 0;
        }
      }
    }
  }
  .unity-form-row {
    padding-bottom: 15px;
    .unity-phone-item {
      width: 100%;
    }
  }  
  .link-unity-btn-wrap .btn {
    margin-top: 25px;
  }
  .sign-up-unity-btn-wrap, .link-unity-btn-wrap {
    text-align: center;
  }
  .unity-countries-wrap .btn {
    width: 100%;
  }
  .unity-form-wrap {
    padding-bottom: 50px;
  }
  .unity-agree-wrap {
    padding-top: 20px;
  }
  .link-unity-modal .form-check label {
    padding-left: 10px;
    width: 95%;
  }
  .link-unity-modal .step2-create-account .sign-up-unity-btn-wrap {
    margin: 50px 0;
  }
  .link-unity-modal .step2-create-account .sign-up-unity-btn-wrap .btn {
    width: 100%;
  }
  .link-unity-modal .step3-link-account .link-unity-btn-wrap {
    margin: 0 0 80px 0;
  }
  .link-unity-modal .step3-link-account .link-unity-btn-wrap .btn {
    width: 100%;
  }
  .step3-link-account .unity-form-wrap {
    padding-bottom: 0;
  }
  .link-unity-modal .form-control {
    width: 100%;
    &.create-account {
      width: 100%;
    }
  }
  .hr-select {
    width: 100%;
  }
  .credit-card-method-item .hr-select {
    width: 100%;
  }
  .confirm-and-pay-content {
    flex-direction: column-reverse;
    padding-top: 40px;
  }
  .wallet-wrap {
    width: 100%;
  }
  .wallet-inner {
    padding: 15px 10px;
    background-color: #23262F;
  }
  .wallet-wrap .buy-now-img-wrap {
    width: 341px;
    display: inline-block;
  }
  .wallet-wrap .buy-now-item-inner {
    width: auto;
    text-align: center;
  }
  .wallet-wrap .buy-now-item-inner {
    border-color: transparent;
  }
  .wallet-wrap .buy-now-text-wrap {
    padding: 0 20px;
  }
  .wallet-wrap .buy-now-text-wrap p {
    border-bottom: 1px solid #353945;
    padding: 0 37px 20px 37px;
  }
  .wallet-wrap h4 {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
  .wallet-item-wrap {
    padding-bottom: 20px;
  }
  .payment-method-wrap {
    width: 100%;
    padding-top: 50px;
  }
  .payment-method-wrap h3 {
    font-size: 40px;
    line-height: 48px;    
  }
  .pay-btn-wrap div {
    width: 100%;
    text-align: center;
  }
  .pay-btn-wrap .btn {
    width: 100%;
    display: inline-block;
  }
  .pm-btn-wrap .btn {
    width: 343px;
  }
  .pm-logo-wrap {
    display: none;
  }
  .congrat-mobile {
    display: block;
    h3 {
      font-size: 40px;
      line-height: 48px;
    }
    .purchase-completed {
      padding: 5px 0 20px 0;
    }
  }
  .payment-success-wrap {
    width: 100%;
    h3 {
      display: none;
    }
    h4 {
      padding-top: 20px;
    }
    .purchase-completed {
      display: none;
    }
    .dollars-added {
      padding: 20px 0;
      border-bottom: 1px solid #353945;
    }
    .order-details {
      padding-top: 20px;
    }
  }
  .in-success-block {
    .wallet-item-wrap {
      padding-top: 0;
    }
    .buy-now-item {
      margin: 0;
    }
    .buy-now-text-wrap {
      p {
        padding-top: 20px;
        border-bottom: none;
      }
    }
  }
  .account-wrap {
    flex-direction: column;
    .account-menu {
      width: 100%;
      .account-menu-inner {
        display: none;
      }
      .account-menu-select {
        display: block;
        .hr-select {
          width: 100%;
          .MuiSelect-select {
            color: #FCFCFD;
          }
        }
      }
    }
    .account-content {
      width: 100%;
      .account-content-inner {
        padding-right: 0;
        .personal-info-form-wrap {
          .pi-form-row {
            flex-direction: column;
            .pi-form-item {
              width: auto;
              .form-control {
                width: 100%;
              }
              &::after {
                right: 17px;
              }
            }
          }
        }
        .link-account-form-wrap {
          .la-form-row {
            flex-direction: column;
            .la-form-item {
              .form-control {
                width: 100%;
              }
              .link-unity-btn-wrap {
                .btn {
                  width: 100%;
                  height: 48px;
                }
              }
            }
          }
        }
        h3 {
          font-size: 32px;
          line-height: 40px;
        }
        h5 {
          margin-top: 50px;
        }
        .credit-card-wrap {
          margin-top: 40px;
          .credit-card {
            flex-direction: column;
          }
          .credit-card-btn-wrap {
            margin-top: 20px;
          }          
        }
        .add-credit-card {
          h5 {
            margin-top: 0;
          }
        }
        .add-payment-btn-wrap {
          padding-top: 20px;
          .btn {
            width: 100%;
            padding: 14px 15px;
          }
        }
      }
    }
  }  
  .account-menu {
    &.support-menu {
      h3 {
        padding-top: 0;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
      }
    }
  }
  .support-messages-wrap {
    width: 100%;
    padding-top: 20px;
    .messages-content {
      padding-right: 0;
      .messages-content-inner {
        padding: 20px 10px 10px 10px;
      }
    }
    .messages-send-wrap {
      display: block;
      .messages-send {
        .form-control {
          width: 100%;
        }
      }
    }
  }
  .unity-form-item {
    .intl-tel-input {
      display: block;
      .form-control {
        width: 100%;
      }
    }
    .flag-container {
      display: none;
    }
    .email-input {
      width: 100%;
    }
  }
  .link-unity-modal {
    .form-check {
      .form-check-input {
        display: inline-block;
      }
    }
  }
  .dropdown-menu {
    right: 5px;
  }
  #sign-in-modal .modal-body {
    padding: 10px;
  }
  .modal-dialog {
    &.privacy-policy {
      .privacy-policy-content {
        h1, h2, h3 {
          font-size: 24px;
          line-height: 28px;
        }        
      }
    }
  }
  .purchase-history-wrap {
    .ph-desktop {
      display: none;
    }
    .ph-mobile {
      display: block;
    }
  }
  .footer-top-menu {
    &.ftm-desktop {
      display: none;
    }
    &.ftm-mobile {
      display: flex;
      .ftm-column {
        margin-right: 20px;
        ul {
          li {
            margin-left: 0;
            margin-bottom: 30px;
          }
        }
        &.ftm-column-right {
          ul {
            li {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }  
}

@media screen and (max-width: 670px) {  
  .top-unity {
    &.top-unity-linked {
      height: 249px;
    }
  }
}  

@media screen and (max-width: 580px) {
  .top-unity {
    &.top-unity-linked {
      height: 240px;
    }
  }
}

@media screen and (max-width: 480px) {
  .wallet-wrap .buy-now-img-wrap {
    width: auto;
    display: inline-block;
  }
  .unity-logo-wrap img.unity-logo-mobile {
    display: block;
    width: 109px;
    height: 32px;
  }
  .unity-logo-wrap img.unity-logo {
    display: none;
  }
  .top-unity {
    &.top-unity-linked {
      height: 233px;
    }
  }
  @supports (-webkit-appearance:none) {
    /*body[style*="overflow: hidden"] {
      touch-action: none;
      -webkit-overflow-scrolling: none;
      position: fixed;
    }*/
  }  
}

@media screen and (max-width: 430px) {
  .top-unity {
    &.top-unity-linked {
      height: 215px;
    }
  }
  .modal-dialog {
    &.privacy-policy {
      max-width: 370px;
      .privacy-policy-content {
        h1, h2, h3 {
          font-size: 24px;
          line-height: 28px;
        }        
      }
    }
  }  
}

@media (min-width: 300px) {
  .modal-dialog {
    max-width: 370px;    
  }
}

@media (min-width: 767px) {
  .modal-dialog {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}